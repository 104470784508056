import type { ComponentPropsWithoutRef } from 'react'

export const ZodiacOsPlain = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    {...props}
    viewBox="0 0 183 183"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M109.325 68.7102C97.0399 85.3862 97.9539 86.2832 114.639 73.9932C135.891 58.3392 160.21 45.4582 174.404 54.5932C175.482 55.2872 176.701 55.6852 175.821 53.1672C173.253 45.8192 169.63 28.7672 180.682 7.98221C184.381 1.02621 182.345 -1.01179 175.384 2.68421C154.635 13.6992 137.604 10.1292 130.216 7.56421C127.658 6.67621 128.061 7.91721 128.762 9.01521C137.825 23.2082 124.959 47.4902 109.325 68.7102Z"
      fill="#DEA336"
    />
    <path
      d="M73.7337 68.7102C86.0187 85.3862 85.1047 86.2832 68.4197 73.9932C47.1677 58.3392 22.8487 45.4582 8.65471 54.5932C7.5767 55.2872 6.35771 55.6852 7.23771 53.1672C9.80571 45.8192 13.4287 28.7672 2.3767 7.98221C-1.3223 1.02621 0.713708 -1.01179 7.67471 2.68421C28.4237 13.6992 45.4547 10.1292 52.8427 7.56421C55.4007 6.67621 54.9977 7.91721 54.2967 9.01521C45.2337 23.2082 58.0997 47.4902 73.7337 68.7102Z"
      fill="#27734B"
    />
    <path
      d="M109.325 114.93C97.0399 98.2544 97.9539 97.3574 114.639 109.647C135.891 125.301 160.21 138.182 174.404 129.047C175.482 128.353 176.701 127.955 175.821 130.473C173.253 137.821 169.63 154.873 180.682 175.658C184.381 182.614 182.345 184.652 175.384 180.956C154.635 169.941 137.604 173.511 130.216 176.076C127.658 176.964 128.061 175.723 128.762 174.625C137.825 160.432 124.959 136.15 109.325 114.93Z"
      fill="#E36574"
    />
    <path
      d="M73.7337 114.93C86.0187 98.2544 85.1047 97.3574 68.4197 109.647C47.1677 125.301 22.8487 138.182 8.65471 129.047C7.5767 128.353 6.35771 127.955 7.23771 130.473C9.80571 137.821 13.4287 154.873 2.3767 175.658C-1.3223 182.614 0.713708 184.652 7.67471 180.956C28.4237 169.941 45.4547 173.511 52.8427 176.076C55.4007 176.964 54.9977 175.723 54.2967 174.625C45.2337 160.432 58.0997 136.15 73.7337 114.93Z"
      fill="#4044A2"
    />
  </svg>
)
